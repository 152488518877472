import { getBackendSrv } from '@grafana/runtime';

export async function getDatasources() {
  const backendSrv = getBackendSrv();
  return backendSrv.get(`/api/datasources`);
}

export async function getDashboardByUid(uid: string) {
  const backendSrv = getBackendSrv();
  return backendSrv.get(`/api/dashboards/uid/${uid}`);
}
