import { DataQuery, DataSourceSettings, PanelModel } from '@grafana/data';

export interface PanelOptions {}

export enum DatasourceType {
  INFLUXDB = 'influxdb',
  GRAPHITE = 'graphite',
  PROMETHEUS = 'prometheus',
  POSTGRES = 'postgres',
  ELASTICSEARCH = 'elasticsearch',
  MYSQL = 'mysql',
}

export enum PanelStatus {
  LOADING = 'Loading',
  DATASOURCE_ERROR = 'Datasource Error',
  PERMISSION_ERROR = 'Permission Error',
  OK = 'Ok',
}

export enum ExportStatus {
  EXPORTING = 'exporting',
  FINISHED = 'finished',
  ERROR = 'error',
}

export type ExportProgress = {
  time: number;
  exportedRowsCount: number;
  progress: number;
  status: ExportStatus;
  errorMessage?: string;
};

export type ExportTask = {
  dashboardUid: string;
  username: string;
  queries: DashboardQuery[];
  timeRange: {
    from: number;
    to: number;
  };
  csvDelimiter: string;
  progress?: ExportProgress;
  id?: string;
  filename?: string;
};

export type DashboardQuery = {
  selected: boolean;
  target: DataQuery;
  panel: PanelModel;
  datasource: DataSourceSettings;
};

export interface Dashboard {
  /**
   * TODO docs
   */
  annotations?: {
    list: any[];
  };
  /**
   * Description of dashboard.
   */
  description?: string;
  /**
   * Whether a dashboard is editable or not.
   */
  editable: boolean;
  /**
   * TODO docs
   */
  fiscalYearStartMonth?: number;
  gnetId?: string;
  graphTooltip: any;
  /**
   * Unique numeric identifier for the dashboard.
   * TODO must isolate or remove identifiers local to a Grafana instance...?
   */
  id?: number;
  /**
   * TODO docs
   */
  links?: any[];
  /**
   * TODO docs
   */
  liveNow?: boolean;
  panels?: PanelModel[];
  /**
   * TODO docs
   */
  refresh?: string | false;
  /**
   * Version of the JSON schema, incremented each time a Grafana update brings
   * changes to said schema.
   * TODO this is the existing schema numbering system. It will be replaced by Thema's themaVersion
   */
  schemaVersion: number;
  /**
   * Theme of dashboard.
   */
  style: 'light' | 'dark';
  /**
   * Tags associated with dashboard.
   */
  tags?: string[];
  /**
   * TODO docs
   */
  templating?: {
    list: any[];
  };
  /**
   * Time range for dashboard, e.g. last 6 hours, last 7 days, etc
   */
  time?: {
    from: string;
    to: string;
  };
  /**
   * TODO docs
   * TODO this appears to be spread all over in the frontend. Concepts will likely need tidying in tandem with schema changes
   */
  timepicker?: {
    /**
     * Whether timepicker is collapsed or not.
     */
    collapse: boolean;
    /**
     * Whether timepicker is enabled or not.
     */
    enable: boolean;
    /**
     * Whether timepicker is visible or not.
     */
    hidden: boolean;
    /**
     * Selectable intervals for auto-refresh.
     */
    refresh_intervals: string[];
    /**
     * TODO docs
     */
    time_options: string[];
  };
  /**
   * Timezone of dashboard,
   */
  timezone?: 'browser' | 'utc' | string;
  /**
   * Title of dashboard.
   */
  title?: string;
  /**
   * Unique dashboard identifier that can be generated by anyone. string (8-40)
   */
  uid?: string;
  /**
   * Version of the dashboard, incremented each time the dashboard is updated.
   */
  version?: number;
  /**
   * TODO docs
   */
  weekStart?: string;
}
